.main-banners {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    margin-bottom: 80px;
}

.main-banners__item {
    position: relative;
    width: 100%;
    height: 583px;
    border-radius: 40px;
    overflow: clip;
}

.main-banners__item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
}

.main-banners__item-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
}

.main-banners__item-info--big {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 41px 60px;
}

.main-banners__item-info--small {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 40px;
}

.main-banners__item-title--first {
    margin: 0;
    font-weight: 600;
    color: #ffffff;
    font-size: 52px;
}

.main-banners__item-title--end {
    font-weight: 600;
    color: #ffffff;
    font-size: 24px;
    position: static;
    top: auto;
    left: auto;
    transition: transform 0.25s ease;
}

.main-banners__item-dark-p--first {
    margin: 0;
    width: 75%;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
}

.main-banners__item-dark-p--end {
    margin: 0;
    width: 75%;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    transition: opacity 0.3s;
}

.main-banners-mobile-dark__link {
    width: 256px;
    height: 50px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    backdrop-filter: blur(2px);
}

.main-banners__button-next {
    position: absolute;
    bottom: -30%;
    left: 20%;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    justify-content: center;
    transition: transform 0.3s ease;
    transform: translate(-50%, -50%);
}
.main-banners__button-next--color {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(30, 30, 30, 0.7);
    border-radius: 120px;
    backdrop-filter: blur(2px);
}

.main-banners__button-next-text {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
}

.main-banners__item:hover .main-banners__button-next {
    transform: translate(-50%, calc(-50% - 154px));
}

.main-banners__item:hover .main-banners__item-dark-p--end {
    opacity: 0;
}

.main-banners__item:hover .main-banners__item-title--end {
    position: absolute;
    bottom: 110px;
    transform: translateY(-40px);
}

.main-banners__item-link {
    width: 100%;
    height: 100%;
}

.initialized {
    display: block;
}

@media (max-width: 1100px) {
    .main-banners__button-next {
        left: 30%;
    }
}

@media (min-width: 901px) {
    .main-banners-mobile {
        display: none;
    }

    .main-banners-mobile__orientir-block .swiper-pagination {
        display: none;
    }
}

@media (max-width: 900px) {
    .main-banners {
        display: none;
    }

    .main-banners-mobile {
        display: block;
    }

    .main-banners-mobile__orientir-block {
        position: relative;
    }

    .main-banners-mobile-contain {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 64px;
    }

    .main-banners-mobile__banner {
        position: relative;
        width: 100%;
        height: 450px;
        max-height: 450px;
        border-radius: 40px;
        overflow: clip;
    }

    .main-banners-mobile__banner img {
        width: 100%;
        min-height: 450px;
        object-fit: cover;
    }

    .main-banners-mobile__banner-dark {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 405px;
        padding: 24px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 8px;
    }

    .main-banners-mobile-dark__title {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
    }

    .main-banners-mobile-dark__p {
        margin: 0;
        width: 80vw;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.5);
    }

    .main-banners-mobile-dark__link {
        width: 250px;
        height: 50px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.2);
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        backdrop-filter: blur(2px);
    }

    .main-banners-mobile__banner-pagination {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
    }

    .swiper-pagination {
        position: absolute;
        --swiper-pagination-top: 24px;
        padding-left: 24px;
        height: 8px;
        display: flex;
        flex-wrap: nowrap;
        gap: 8px;
        z-index: 9;
    }

    .swiper-pagination-bullet {
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        --swiper-pagination-color: rgba(0, 0, 0, 0.5);
    }

    .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        border-radius: 20px;
        --swiper-pagination-color: #ffffff;
        background: white;
    }

    .swiper-container-slider {
        display: none;
        overflow: clip;
    }

    .swiper-container-slider.initialized {
        display: block;
    }
}

@media (max-width: 430px) {
    .main-banners-mobile-dark__link {
        width: 86%;
    }
}
