.main-banners {
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin-bottom: 80px;
  display: grid;
}

.main-banners__item {
  border-radius: 40px;
  width: 100%;
  height: 583px;
  position: relative;
  overflow: clip;
}

.main-banners__item-img {
  object-fit: cover;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.main-banners__item-dark {
  background: #00000080;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.main-banners__item-info--big {
  flex-direction: column;
  gap: 8px;
  padding: 41px 60px;
  display: flex;
}

.main-banners__item-info--small {
  flex-direction: column;
  gap: 8px;
  padding: 40px;
  display: flex;
}

.main-banners__item-title--first {
  color: #fff;
  margin: 0;
  font-size: 52px;
  font-weight: 600;
}

.main-banners__item-title--end {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  transition: transform .25s;
  position: static;
  top: auto;
  left: auto;
}

.main-banners__item-dark-p--first {
  color: #fffc;
  width: 75%;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.main-banners__item-dark-p--end {
  color: #fffc;
  width: 75%;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  transition: opacity .3s;
}

.main-banners-mobile-dark__link {
  color: #fff;
  backdrop-filter: blur(2px);
  background: #fff3;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 50px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.main-banners__button-next {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: auto;
  height: auto;
  transition: transform .3s;
  display: flex;
  position: absolute;
  bottom: -30%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.main-banners__button-next--color {
  backdrop-filter: blur(2px);
  background-color: #1e1e1eb3;
  border-radius: 120px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  display: flex;
}

.main-banners__button-next-text {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.main-banners__item:hover .main-banners__button-next {
  transform: translate(-50%, calc(-50% - 154px));
}

.main-banners__item:hover .main-banners__item-dark-p--end {
  opacity: 0;
}

.main-banners__item:hover .main-banners__item-title--end {
  position: absolute;
  bottom: 110px;
  transform: translateY(-40px);
}

.main-banners__item-link {
  width: 100%;
  height: 100%;
}

.initialized {
  display: block;
}

@media (width <= 1100px) {
  .main-banners__button-next {
    left: 30%;
  }
}

@media (width >= 901px) {
  .main-banners-mobile, .main-banners-mobile__orientir-block .swiper-pagination {
    display: none;
  }
}

@media (width <= 900px) {
  .main-banners {
    display: none;
  }

  .main-banners-mobile {
    display: block;
  }

  .main-banners-mobile__orientir-block {
    position: relative;
  }

  .main-banners-mobile-contain {
    flex-wrap: nowrap;
    margin-bottom: 64px;
    display: flex;
    position: relative;
  }

  .main-banners-mobile__banner {
    border-radius: 40px;
    width: 100%;
    height: 450px;
    max-height: 450px;
    position: relative;
    overflow: clip;
  }

  .main-banners-mobile__banner img {
    object-fit: cover;
    width: 100%;
    min-height: 450px;
  }

  .main-banners-mobile__banner-dark {
    background: #00000080;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    height: 405px;
    padding: 24px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  .main-banners-mobile-dark__title {
    color: #fff;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }

  .main-banners-mobile-dark__p {
    color: #ffffff80;
    width: 80vw;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .main-banners-mobile-dark__link {
    color: #fff;
    backdrop-filter: blur(2px);
    background: #fff3;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50px;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    display: flex;
  }

  .main-banners-mobile__banner-pagination {
    flex-wrap: nowrap;
    gap: 16px;
    display: flex;
  }

  .swiper-pagination {
    --swiper-pagination-top: 24px;
    z-index: 9;
    flex-wrap: nowrap;
    gap: 8px;
    height: 8px;
    padding-left: 24px;
    display: flex;
    position: absolute;
  }

  .swiper-pagination-bullet {
    --swiper-pagination-color: #00000080;
    background-color: #00000080;
    border-radius: 20px;
    width: 16px;
    height: 16px;
  }

  .swiper-pagination-bullet-active {
    --swiper-pagination-color: #fff;
    background: #fff;
    border-radius: 20px;
    width: 8px;
    height: 8px;
  }

  .swiper-container-slider {
    display: none;
    overflow: clip;
  }

  .swiper-container-slider.initialized {
    display: block;
  }
}

@media (width <= 430px) {
  .main-banners-mobile-dark__link {
    width: 86%;
  }
}
/*# sourceMappingURL=index.5208b193.css.map */
